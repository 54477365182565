import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import API from '../../../utils/API';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import 'react-datepicker/dist/react-datepicker.css';
import './transactionsNew.css';

const TransactionsNew = (props: any) => {
  const reduxOrg = useSelector((state: any) => state.org);
  const [formState, setFormState] = useState({
    duration: 'yearToDate',
    fundraiser: 'all'
  });
  const [campaigns, setCampaigns] = useState<any>([]);
  const [reports, setReports] = useState([]);
  const [reportsM, setReportsM] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [firstCall, setFirstCall] = useState(false);
  const [endDate, setEndDate] = useState(new Date());
  const [errors, setErrors] = useState({
    duration: false,
    fundraiser: false
  });

  const headers = [
    { label: 'Date', key: 'date' },
    { label: 'Donor Email', key: 'email' },
    { label: 'Fundraiser', key: 'fundraiser' },
    { label: 'Amount USD', key: 'amount' }
  ];

  const campIdToName = (id: string) => {
    for (let i = 0; i < campaigns.length; i++) {
      if (campaigns[i].id === id) {
        return campaigns[i].name;
      }
    }
  };

  const handleStartDateChange = (date: any) => {
    setStartDate(date);
    console.log(date);
  };

  const handleEndDateChange = (date: any) => {
    setEndDate(date);
    console.log(date);
  };

  useEffect(() => {
    API.getNpCampaigns(reduxOrg._id).then((result) => {
      console.log(result.data);
      setCampaigns(
        result.data.sort((a: any, b: any) => b.createdAt - a.createdAt)
      );
    });
  }, []);

  //Following 2 useEffects for initial transaction population.
  useEffect(() => {
    setFirstCall(true);
  }, [campaigns]);

  useEffect(() => {
    let query = `?duration=${formState?.duration}&fundraiser=${formState?.fundraiser}`;
    API.getDonationReports(reduxOrg._id, query)
      .then((response) => {
        console.log(response , 'asasasas');
        if(response?.data.length === 0){
          setReportsM('You don’t have any donations to show')
        }else{
          setReports(response.data.reverse())
        }
        })
      .catch((err: any) => console.log(err));
  }, [firstCall]);

  useEffect(() =>{},[reportsM])

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
    setErrors({ ...errors, [name]: false });
    console.log(formState);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const err = { duration: false, fundraiser: false };

    if (!formState?.duration) {
      err.duration = true;
    }

    if (!formState?.fundraiser) {
      err.fundraiser = true;
    }
    setErrors(err);

    if (!formState?.duration || !formState?.fundraiser) {
      return;
    }

    let query = `?duration=${formState?.duration}&fundraiser=${formState?.fundraiser}`;

    if (formState?.duration === 'customRange') {
      query += `&startDate=${startDate}&endDate=${endDate}`;
    }

    const response: any = await API.getDonationReports(
      reduxOrg._id,
      query
    ).catch((err: any) => console.log(err));

    if (response?.status === 200 && Array.isArray(response.data)) {
      console.log(response , 'asasasas');
      
      console.log(response.data);
      if (response.data.length === 0) {
        setReportsM('You don’t have any donations to show')
      }else{
        setReports(response.data.reverse());
      }

    } else {
      alert("Couldn't retrieve records.  Please try again.");
    }
  };

  const toDollars = (amount: number) => {
    return (amount / 100).toFixed(2);
  };

  return (
    <>
      <div className=''>
        <select
          name='duration'
          className={
            errors.duration
              ? 'txn-calendar-select-css transactions-input-error'
              : 'txn-calendar-select-css'
          }
          onChange={handleChange}
        >
          <option value={undefined} disabled>
            Choose Duration
          </option>
          <option value='yearToDate' selected>
            Year to Date
          </option>
          <option value='monthToDate'>Month to Date</option>
          <option value='customRange'>Custom Date Range</option>
        </select>
        <select
          name='fundraiser'
          className={
            errors.fundraiser
              ? 'txn-calendar-select-css transactions-input-error'
              : 'txn-calendar-select-css'
          }
          onChange={handleChange}
        >
          <option value={undefined} disabled>
            Choose Fundraiser
          </option>
          <option value='nonFundraiser'>Non-Fundraiser Donations</option>
          <option value='all' selected>
            All Donations
          </option>
          {campaigns.map((camp: any) => (
            <option value={camp.id}>{camp.name}</option>
          ))}
        </select>
        <Button
          className='transactions-buttons-generate'
          onClick={handleSubmit}
        >
          Show Report
        </Button>

        {formState && formState.duration === 'customRange' && (
          <>
            <div style={{display: 'flex'}}>
              <div style={{display: 'flex' , flexDirection : 'column'}}>
                <span className='transactions-span-from'>From:</span>
                <DatePicker
                  className='txn-calendar-select-css'
                  selected={startDate}
                  onChange={handleStartDateChange}
                  selectsStart
                  startDate={startDate}
                />
              </div>
              <div style={{display: 'flex' , flexDirection : 'column'}}>
                <span className='transactions-span-to'>To:</span>
                <DatePicker
                  className='txn-calendar-select-css'
                  selected={endDate}
                  onChange={handleEndDateChange}
                  selectsEnd
                  endDate={endDate}
                />
              </div>
            </div>



          </>
        )}

        <div className='manage-pay-transactions'>
          <div className='header-for-sticky'>
            <span style={{ fontSize: 'large', fontWeight: 'bold' }}>
              Transactions
            </span>
            {reports.length > 0 ? 
            <div className='transaction-row transaction-titles'>
              <span>Date</span>
              <span>Amount</span>
              <span>Donor Email</span>
              <span>Fundraiser</span>
            </div>
            :
            null
          }
          </div>
          {reports.length > 0 ? 
          <>
          {reports.map((trans: any, i: number) => {
            const date = new Date(trans.createdAt);
            return (
              <div
                className={
                  i % 2 !== 0 ? 'transaction-row row-dark' : 'transaction-row'
                }
              >
                <span>
                  {date.getMonth() + 1}/{date.getDate()}/{date.getFullYear()}
                </span>
                <span>${toDollars(trans.amount)}</span>

                <span>{trans.donorEmail}</span>
                <span>
                  {trans.campaignID
                    ? campIdToName(trans.campaignID)
                    : 'Direct Donation'}
                </span>
              </div>
            );
          })}
          </>
          :
          <div className='no-data-to-show'>{reportsM}</div>
        }
        </div>
      </div>
      {reports.length > 0 ? 
      <div>
        <span className='transaction-page-total-number'>
          Total: $
          {reports.length > 0 &&
            (
              reports.reduce((total, rep: any) => total + rep.amount, 0) / 100
            ).toFixed(2)}
        </span>
        <CSVLink
          className='btn transactions-buttons'
          data={reports.map((trans: any) => {
            let date = new Date(trans.createdAt);
            return {
              date: `${
                date.getMonth() + 1
              }/${date.getDate()}/${date.getFullYear()}`,
              amount: toDollars(trans.amount),
              email: trans.donorEmail,
              fundraiser: trans.campaignID
                ? campIdToName(trans.campaignID)
                : 'Direct Donation'
            };
          })}
          headers={headers}
          filename={`NEDDIE-donation-report.csv`}
        >
          Download Report
        </CSVLink>
      </div>
      : null }
    </>
  );
};

export default TransactionsNew;
