import axios from 'axios';
import { IFormInputs } from '../pages/signUpPage/validation';

export default {
  updateDashboard: (input: any) => {
    return axios.put('/api/updateDashboard', input);
  },
  // ------------------- hhahahahahaha
  spotlightWriteFunction: (input: any) => {
    return axios.put(`/api/updateSpotlight`, input);
  },
  spotlightReadFunction: () => {
    return axios.get(`/api/getSpotlight`);
  },
  getNonprofit: () => {
    return axios.get(`/api/getNonprofit`);
  },
  authenticate: (input: IFormInputs) => {
    return axios.post(`/api/authenticate`, input);
  },
  getProfile: (id: string) => {
    return axios.get(`/api/find/nonprofit/${id}`);
  },
  getNonProfitEvents: (id: string) => {
    return axios.get(`/api/find/nonprofit/events/${id}`);
  },
  getNpCampaigns: (id: string) => {
    return axios.get(`/api/getNpCampaigns/${id}`);
  },
  //get User Profile
  getUserPhoneNumber: (id: string) => {
    return axios.get(`/api/find/user/phoneNumber/${id}`);
  },
  subscribe: (email: any) => {
    return axios.post('/api/subscribe', email);
  },
  forgot: (email: any) => {
    return axios.post('/api/forgot', email);
  },
  findNP: (term: string) => {
    return axios.get(`/api/find/nonprofit/${term}`);
  },
  check: (email: string | undefined) => {
    return axios.get(`/api/check/${email}`);
  },
  confirm: (id: string, type: string) => {
    return axios.get(`/api/confirm/${type}/${id}`);
  },
  findByEin: (ein: string | undefined) => {
    return axios.get(`/api/find/nonprofitExists/${ein}`);
  },
  approve: (id: string, type: string, pw: string) => {
    return axios.put(`/api/approve/${type}/${id}/${pw}`);
  },
  register: (input: IFormInputs, type: string) => {
    return axios.post(`/api/register/${type}`, input);
  },
  saveWishlist: (input: any) => {
    return axios.post(`/api/saveWishlist`, input);
  },
  deleteWishlist: (input: any) => {
    return axios.post(`/api/deleteWishlist`, input);
  },
  saveMarketplace: (input: any) => {
    return axios.post(`/api/saveMarketplace`, input);
  },
  deleteMarketplace: (input: any) => {
    return axios.post(`/api/deleteMarketplace`, input);
  },
  updateNP: (input: any) => {
    return axios.put(`/api/updateNP`, input);
  },
  updateNPCampaigns: (input: any) => {
    return axios.put(`/api/updateNPCampaigns`, input);
  },
  updateNPEvents: (input: any) => {
    return axios.put(`/api/updateNPEvents`, input);
  },

  updateNPSocialMedias: (input: any) => {
    return axios.put(`/api/updateNPSocialMedias`, input);
  },
  searchNonProfit: (
    search_query: any,
    limit: number,
    curDisplayRec: number,
    showAll?: boolean
  ) => {
    return axios.get(`/api/search/nonprofit`, {
      params: {
        search_query: search_query,
        limit: limit,
        currentDisplayRecords: curDisplayRec,
        showAll: showAll
      }
    });
  },

  createStripeAccount: (input: any) => {
    return axios.post(`/api/createStripeAccount`, input);
  },
  getStripeAcct: (stripeID: string) => {
    console.log(stripeID);
    return axios.get(`/api/getStripeAcct/${stripeID}`);
  },
  getBankAcct: (stripeID: string) => {
    return axios.get(`/api/getBankAcct/${stripeID}`);
  },
  updateBankAcct: (stripeID: string, acct: string, upObj: object) => {
    return axios.put(`/api/updateBankAcct/${stripeID}/${acct}`, upObj);
  },
  removeBankAcct: (stripeID: string, acct: string) => {
    return axios.put(`/api/removeBankAcct/${stripeID}/${acct}`);
  },
  getStripeBalance: (id: string) => {
    return axios.get(`/api/getStripeBalance/${id}`);
  },
  getTransactions: (id: string) => {
    return axios.get(`/api/getTransactions/${id}`);
  },
  getDonationReports: (npID: string, query: string) => {
    return axios.get(`/api/getDonationReports/${npID}/${query}`);
  },
  getDonorDonations: (donorEmail: string, query: string) => {
    return axios.get(`/api/getDonorDonations/${donorEmail}/${query}`);
  },

  createStripeAccountLinks: (input: any) => {
    return axios.post(`/api/createStripeAccountLinks`, input);
  },

  updateUserStripeInfo: (input: any) => {
    return axios.post(`/api/updateUserStripeInfo`, input);
  },

  emailDonorReport: (input: any) => {
    return axios.post('/api/emailDonorReport', input);
  },

  // getMonthlyTransactions: (npID: string) => {
  //   return axios.get(`/api/getMonthlyTransactions/${npID}`);
  // },

  createPaymentIntent: (input: any) => {
    return axios.post(`/api/createPaymentIntent`, input);
  },
  createDonation: (input: {
    id: string;
    campaign: string;
    email: string;
    amount: number;
    optIn: Boolean;
    tip: number;
    stripeTrans: string;
  }) => {
    return axios.post(`/api/createDonation`, input);
  },

  addNPEvent: (input: {
    orgID: any;
    eventType: string;
    post: string;
    eventImage: any;
    name: string;
    description: string;
    longDescription: string;
    eventDate: Date;
    timeStart: Date;
    timeEnd: Date;
    timezone: string;
    recurringEvent: Boolean;
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    state?: string;
    zipcode?: any;
    host: string;
    volunteerOpportunities: string;
    tickets: Array<any>;
  }) => {
    return axios.post(`/api/createEvent`, input);
  },

  getOneCampaign: (id: string) => {
    return axios.get(`/api/getOneCampaign/${id}`);
  },

  searchCampaign: (
    search_query: any,
    limit: number,
    curDisplayRec: number,
    showAll: boolean
  ) => {
    return axios.get(`/api/search/campaign`, {
      params: {
        search_query: search_query,
        limit: limit,
        currentDisplayRecords: curDisplayRec,
        showAll: showAll
      }
    });
  },
  deleteCampaign: (id: string, npID: string) => {
    axios.delete(`/api/campaign/delete/${npID}/${id}`);
  },
  getActiveCampaigns: () => {
    return axios.get('api/find/campaigns/active');
  },

  //authenticate token for resetting password
  authenticateResetPwToken: (token: string) => {
    return axios.get(`/api/authenticate/token`, {
      params: {
        resetPasswordToken: token
      }
    });
  },

  //update password
  updatePassword: (userId: string, pw: string) => {
    return axios.put(`/api/update/password`, {
      userId: userId,
      password: pw
    });
  },

  updateUser: (updateUser: any) => {
    return axios.put(`/api/update/user`, { updateUser });
  },

  sendFeedback: (userName: string, email: string, description: string) => {
    return axios.get(`/api/sendFeedback`, {
      params: {
        userName: userName,
        email: email,
        description: description
      }
    });
  },

  checkNickname: (nickname: string | undefined) => {
    return axios.get(`/api/checkNickname/${nickname}`);
  },

  updateNickname: (input: { npID: string; nickname: string }) => {
    return axios.put(`/api/updateNickname`, input);
  },

  checkGuideStar: (ein: string) => {
    return axios.get(`/api/checkGuidestar/${ein}`);
  },

  toggleLive: (npID: string) => {
    return axios.put('/api/toggleLive', { npID });
  },

  checkPassword: (userId: string, pw: string) => {
    return axios.get(`/api/check/password/${userId}/${pw}`);
  },

  fetchUserPreferences: (userId: string) => {
    return axios.get(`/api/fetch/userPreferences/${userId}`);
  },

  authenticateAdmin: (password: string) => {
    return axios.post(`/api/authenticateAdmin`, { password });
  },

  updateNPImpacts: (input: any) => {
    return axios.put(`/api/updateNPImpacts`, input);
  },

  getCalendarEvents: () => {
    return axios.get('api/find/events');
  },
  search: (
    search_query: any,
    limit: number,
    curDisplayRec: number,
    searchEngineCategory: string,
    showAll?: boolean
  ) => {
    return axios.get(`/api/search`, {
      params: {
        search_query: search_query,
        limit: limit,
        currentDisplayRecords: curDisplayRec,
        searchEngineCategory: searchEngineCategory,
        showAll: showAll
      }
    });
  },

  handleEventLikedButton: (
    eventId: string,
    userId: string,
    isLiked: boolean
  ) => {
    return axios.put(`/api/handleEventLikedButton`, {
      eventId,
      userId,
      isLiked
    });
  },

  getUserEvents: (userId: string) => {
    return axios.get(`/api/getUserEvents/${userId}`);
  },

  createUpdate: (newUpdateForm: {
    title: string;
    description: string;
    link: string;
  }) => {
    return axios.post(`/api/createUpdate`, newUpdateForm);
  },

  getUpdate: () => {
    return axios.get(`/api/getUpdate`);
  },

  deleteUpdate: (id: string) => {
    return axios.delete(`/api/deleteUpdate/${id}`);
  },

  updateUpdate: (update: {
    _id: string;
    title: string;
    description: string;
    link: string;
    createdAt: Date;
    upDatedAt: Date;
  }) => {
    console.log('&*&*&*&*&*&*&*&*&*&');
    return axios.put(`/api/updateUpdate`, update);
  },
  sendBusinessSignUpEmail: (info: {
    businessName: string;
    contactName: string;
    email: string;
  }) => {
    return axios.get(`/api/sendBusinessSignUpEmail`, {
      params: {
        businessName: info.businessName,
        email: info.email,
        contactName: info.contactName
      }
    });
  },

  getEvent: (id: string, userId?: string) => {
    return axios.get(`/api/getEvent`, {
      params: {
        eventId: id,
        userId: userId === undefined ? 0 : userId
      }
    });
  }
};
